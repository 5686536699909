import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import { motion as Motion } from 'framer-motion';

import { ThemeContext } from '../context/ThemeState';
import { Main } from '../layouts/Main';
import { PageBuilder } from '../components/PageBuilder';
import { Footer } from '../components/Footer';

interface I_LegalPage {
    data: any;
}

const LegalPage = ({ data: { contentJson } }: I_LegalPage) => {
    const { changeTheme } = useContext(ThemeContext);
    const { description, keywords, canonical, siteUrl, body, title, imageSocialShare } = contentJson;

    useEffect(() => {
        changeTheme(false);
    }, []);

    return (
        <Main seoProps={{ title, description, keywords, siteUrl, imageSocialShare, canonical }}>
            <Motion.div
                animate={{
                    opacity: [0, 1],
                    y: [100, 0],
                    transition: { ease: 'easeInOut', duration: 0.75, delay: 0.4 }
                }}
                style={{ opacity: 0 }}
            >
                <PageBuilder body={body} />
                <Footer />
            </Motion.div>
        </Main>
    );
};

export default LegalPage;

export const data = graphql`
    query {
        contentJson(page: { eq: "legal" }) {
            title
            description
            keywords
            canonical
            siteUrl
            imageSocialShare
            body {
                type
                modifiers
                gridWrapper {
                    modifiers
                    spacingTop
                    spacingBottom
                }
                gridWrapperItems {
                    responsive
                }
                text {
                    atom
                    content
                    withMark
                    markModifiers
                    modifiers
                    spacingTop
                    spacingBottom
                    type
                    children {
                        atom
                        content
                        modifiers
                        spacingBottom
                        type
                    }
                }
            }
        }
    }
`;
